import http from "@/api/axios.js";
/**
 * 社保政策管理api
 */

// 获取城市下拉List
export function GetCityData(data) {
  return http({
    url: `/api/SocialProduct/GetProductCities`,
    method: "get",
    params: data,
  });
}

// 获取退休时间管理列表
export function SocialRetireGetItems(data) {
  return http({
    url: `/api/SocialRetire/GetItems`,
    method: "get",
    params: data,
  });
}

// 获取退休白名单数据
export function GetWhites(data) {
  return http({
    url: `/api/SocialRetire/GetWhites`,
    method: "get",
    params: data,
  });
}

// 删除退休白名单
export function DelWhite(data) {
  return http({
    url: `/api/SocialRetire/DelWhite`,
    method: "put",
    params: data,
  });
}

// 新增退休白名单
export function AddWhite(data) {
  return http({
    url: `/api/SocialRetire/AddWhite`,
    method: "post",
    data: data,
  });
}
