<template>
  <div id="retirementTimeManagement" ref="retirementTimeManagement">
    <div class="control-panel">
      <div class="control-panel-left">
        <div>
          <p>城市</p>
          <a-select
            ref="select"
            class="city-filter"
            v-model:value="currentCity"
            show-search
            allowClear
            :options="cityList"
            :filter-option="cityFilter"
            @change="cityChange"
          >
          </a-select>
        </div>
        <a-button @click="search">查询</a-button>
      </div>
      <div class="control-panel-right">
        <a-button type="primary" @click="getWhiteList">退休白名单</a-button>
      </div>
    </div>
    <div class="table-panel">
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'no'">
            <span>
              {{ record.no }}
            </span>
          </template>
        </template>
      </a-table>
    </div>
    <!-- 退休白名单 -->
    <a-modal
      :getContainer="$refs.retirementTimeManagement"
      :centered="false"
      :maskClosable="false"
      v-model:visible="whiteVisible"
      width="600px"
      title="退休白名单"
      :footer="false"
    >
      <div id="whiteList" ref="whiteList">
        <div class="white-control-panel">
          <a-input-search
            v-model:value="whiteSearch"
            placeholder=""
            :loading="whiteLoading"
            allowClear
            @search="getWhiteList"
            enter-button
          />
          <a-button
            type="primary"
            :loading="whiteLoading"
            @click="addWhiteVisible = true"
            >新增</a-button
          >
        </div>
        <div class="white-table-panel">
          <a-table
            size="small"
            :scroll="{ y: 300 }"
            :dataSource="whiteDataSource"
            :columns="whiteColumns"
            :loading="whiteLoading"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'control'">
                <a-button type="primary" @click="deleteWhite(record.idCard)"
                  >删除</a-button
                >
              </template>
            </template>
          </a-table>
        </div>
        <!-- 白名单新增 -->
        <a-modal
          :getContainer="$refs.whiteList"
          :centered="false"
          :maskClosable="false"
          :confirmLoading="addWhiteLoading"
          v-model:visible="addWhiteVisible"
          width="480px"
          title="白名单新增"
          ok-text="确认"
          cancel-text="取消"
          @ok="confirmAddWhite"
        >
          <a-input v-model:value="addWhiteIdCard" placeholder="" />
        </a-modal>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { onMounted, reactive, toRefs, createVNode } from "vue";
import {
  GetCityData,
  SocialRetireGetItems,
  GetWhites,
  DelWhite,
  AddWhite,
} from "@/api/socialSecurityPolicyManagement";
import { Modal, message } from "ant-design-vue";
export default {
  setup() {
    const state = reactive({
      currentCity: "",
      cityList: [],
      dataSource: [],
      columns: [
        {
          title: "序号",
          dataIndex: "no",
          key: "no",
        },
        {
          title: "城市",
          dataIndex: "city",
          key: "city",
        },
        {
          title: "退休提醒时间（月）",
          dataIndex: "remindMonthCount",
          key: "remindMonthCount",
        },
        {
          title: "强制退休时间（月）",
          dataIndex: "mandatoryMonthCount",
          key: "mandatoryMonthCount",
        },
      ],
      loading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      whiteVisible: false,
      whiteSearch: "",
      whiteLoading: false,
      whiteDataSource: [],
      whiteColumns: [
        {
          title: "身份证号",
          dataIndex: "idCard",
          key: "idCard",
        },
        { title: "操作", dataIndex: "control", key: "control", width: 150 },
      ],
      addWhiteVisible: false,
      addWhiteIdCard: "",
      addWhiteLoading: false,
    });

    // 城市选择
    const cityChange = (val) => {
      state.currentCity = val ? val : "";
    };

    // 筛选城市
    const cityFilter = (inputVal, option) => {
      return option.value.indexOf(inputVal) >= 0;
    };

    // 获取城市数据
    const getCityData = async () => {
      await GetCityData()
        .then((res) => {
          let l = [];
          res.data.forEach((i) => {
            let o = {
              value: i === "全部" ? "" : i,
              label: i,
            };
            l.push(o);
          });
          state.cityList = l;
          state.currentCity = l[0].value;
        })
        .catch((err) => {
          Modal.error({
            content: err.response.data,
          });
        });
    };

    // 查询
    const search = () => {
      state.pagination.current = 1;
      getTableData();
    };

    // 获取表格数据
    const getTableData = async () => {
      state.loading = true;
      await SocialRetireGetItems({
        city: state.currentCity,
        page: state.pagination.current,
        rows: state.pagination.pageSize,
      })
        .then((res) => {
          state.dataSource = res.data.items;
          state.pagination.total = res.data.total;
          state.loading = false;
        })
        .catch((err) => {
          Modal.error({
            content: err.response.data,
          });
          state.loading = false;
        });
    };

    // handleTableChange
    const handleTableChange = (page) => {
      state.pagination.current = page.current;
      state.pagination.pageSize = page.pageSize;
      getTableData();
    };

    // 获取退休白名单
    const getWhiteList = async () => {
      state.whiteVisible = true;
      state.whiteLoading = true;
      await GetWhites({
        idCard: state.whiteSearch,
      })
        .then((res) => {
          let wl = [];
          res.data.forEach((i) => {
            let obj = {
              idCard: i,
            };
            wl.push(obj);
          });
          state.whiteDataSource = wl;
          state.whiteLoading = false;
        })
        .catch((err) => {
          Modal.error({
            content: err.response.data,
          });
          state.whiteLoading = false;
        });
    };

    // 删除退休白名单
    const deleteWhite = async (idCard) => {
      function mountElement() {
        return document.getElementById("whiteList");
      }
      const modal = Modal.confirm({
        getContainer: mountElement,
        title: "提示",
        icon: createVNode(ExclamationCircleOutlined),
        content: "是否确认删除？",
        okText: "确认",
        cancelText: "取消",
        async onOk() {
          await DelWhite({
            idCard: idCard,
          })
            .then(() => {
              message.success("删除成功！");
              state.whiteSearch = "";
              getWhiteList();
            })
            .catch((err) => {
              Modal.error({
                content: err.response.data,
              });
            });
        },
        async onCancel() {
          modal.destroy();
        },
      });
    };

    // 确定添加白名单
    const confirmAddWhite = async () => {
      state.addWhiteLoading = true;
      await AddWhite({
        idCard: state.addWhiteIdCard,
      })
        .then(() => {
          state.addWhiteVisible = false;
          state.addWhiteLoading = false;
          state.addWhiteIdCard = "";
          message.success("新增成功！");
          getWhiteList();
        })
        .catch((err) => {
          Modal.error({
            content: err.response.data,
          });
          state.addWhiteLoading = false;
        });
    };

    onMounted(() => {
      getCityData();
      getTableData();
    });

    return {
      ...toRefs(state),
      cityChange,
      cityFilter,
      getCityData,
      search,
      getTableData,
      handleTableChange,
      getWhiteList,
      deleteWhite,
      confirmAddWhite,
    };
  },
};
</script>
<style lang="less" scoped>
#retirementTimeManagement {
  .control-panel {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .control-panel-left {
      display: flex;
      align-items: flex-end;
    }
    .control-panel-right {
      display: flex;
      align-items: flex-end;
    }
    .city-filter {
      width: 200px;
      margin-right: 60px;
    }
  }
  .table-panel {
    margin: 30px 0 0;
  }
  #whiteList {
    .white-control-panel {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .white-table-panel {
      margin-top: 30px;
    }
  }
}
</style>
